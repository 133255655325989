import React from "react";
import SEO from "../components/atoms/seo";

import Layout from "../components/organisms/layout";
import Heading from "../components/molecules/heading";

import content from "../data/pages/questionnaire/content.json";

const ThankYouPage = () => (
  <Layout hideSecureForm>
    <SEO title="Thank You" />
    <Heading
      title={content.onSubmit.message.title}
      subTitle={content.onSubmit.message.subTitle}
      weight="light"
    />
  </Layout>
);

export default ThankYouPage;
